<template>
<div class="container1150">
<header><strong>排水路缘石</strong></header>
<main>
<p>
                排水路缘石采用树脂混凝土制成，将路缘石与排水系统的功能合二为一
            </p>
<div class="img-list">
<div class="img1"></div>
<div class="img2"></div>
</div>
<h2>产品特性</h2>
<p>● · 透水路面最佳解决方案</p>
<div class="text-img">
<p>
                    ·
                    精密的设计，可以实现透水路面（透水沥青、透水混凝土）的收水、溢流、排水及侧排多种功能于一身
                </p>
<div class="img1"></div>
</div>

<p>● · 两种高度规格，高达D400的承重等级，可使用不同形态的道路。</p>

<div class="text-img2">
<div>
<p>· 335mm适用于较窄路面（双向两车道或双向四车道）</p>
<p>· 500mm适用于较宽路面（双向四车道或双向六车道及以上）</p>
</div>
<div class="img1"></div>
</div>
<p>● ·安装简单，维护便捷</p>
<div class="img1"></div>
<div class="img2"></div>

</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .text-img {
            display: flex;
            justify-content: space-around;
            p {
                width: 40%;
                padding: 50px 0 0 20px;
                box-sizing: border-box;
            }
            .img1 {
                // width: 100%;
                width: 500px;
                height: 300px;
                margin: -60px auto 0;
                background-image: url("/img/product/6-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .text-img2 {
            display: flex;
            justify-content: space-around;
            div {
                width: 40%;
                padding: 50px 0 0 20px;
                box-sizing: border-box;
            }
            .img1 {
                // width: 100%;
                width: 470px;
                height: 300px;
                margin: -60px auto 0;
                background-image: url("/img/product/6-4.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/6-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/6-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img1 {
            // width: 100%;
            width: 1100px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/6-5.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            width: 1094px;
            height: 320px;
            margin: 10px auto;
            background-image: url("/img/product/6-6.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>